@import './functions';

@mixin grid() {
  div[class='row'] {
    outline: 1px dotted rgba(0, 0, 0, 0.25);
    outline-offset: -1px;
  }

  div[class*='col-'] {
    background-color: rgba(255, 0, 0, 0.2);
    outline: 1px dotted rgba(0, 0, 0, 0.5);
    outline-offset: -1px;
  }
}

@mixin highlight($color: null) {
  @if $color == red {
    background-color: rgba(255, 0, 0, 0.2);
  }
  @if $color == blue {
    background-color: rgba(0, 0, 255, 0.2);
  }
  @if $color == green {
    background-color: rgba(0, 255, 0, 0.2);
  }
  @if $color == yellow {
    background-color: rgba(255, 255, 0, 0.2);
  }
}

@mixin media-breakpoint-custom($min, $max) {
  @if $min == 'xs' or $min == 'sm' or $min == 'md' or $min == 'lg' or $min == 'xl' {
    $min: breakpoint-min($min, $grid-breakpoints);
  }
  @if $max == 'xs' or $max == 'sm' or $max == 'md' or $max == 'lg' or $max == 'xl' {
    $max: breakpoint-max($max, $grid-breakpoints);
  }
  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @media (min-width: $min) {
      @content;
    }
  } @else if $min == null {
    @media (max-width: $max) {
      @content;
    }
  }
}

@mixin media-breakpoint-height($min, $max) {
  @if $min != null and $max != null {
    @media (min-height: $min) and (max-height: $max) {
      @content;
    }
  } @else if $max == null {
    @media (min-height: $min) {
      @content;
    }
  } @else if $min == null {
    @media (max-height: $max) {
      @content;
    }
  }
}

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf) {
  $src: null;

  $extmods: (
    eot: '?#iefix',
    svg: '#' + str-replace($name, ' ', '_'),
  );

  $formats: (
    eot: 'embedded-opentype',
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + '.' + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
    font-display: swap;
  }
}

@mixin placeholder($color: $input-color-placeholder) {
  // Firefox
  &:-moz-placeholder,
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // override Firefox's unusual default opacity, see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: $color;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: $color;
  } // Safari and Chrome
}

@mixin transformCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin square-rem($size) {
  @include size-rem($size, $size);
}

@mixin size-rem($width, $height) {
  width: rem($width);
  height: rem($height);
}

@mixin media-screen-hidpi {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    @content;
  }
}

@mixin bullets {
  ul {
    li {
      display: list-item;
      list-style-type: disc;
      list-style-position: outside;
      margin-left: rem(20);
      margin-bottom: rem(15);
    }
  }
  ol {
    li {
      display: list-item;
      list-style-type: decimal;
      list-style-position: outside;
      margin-left: rem(20);
      margin-bottom: rem(15);
    }
  }
  ul > li,
  ol > li {
    margin-top: rem(15);
  }
}

@mixin oldbrowsers() {
  html.safari1,
  html.safari2,
  html.safari3,
  html.safari4,
  html.safari5,
  html.safari6,
  html.safari7,
  html.safari8,
  html.safari9,
  html.safari10,
  html.safari11,
  html.ios1,
  html.ios2,
  html.ios3,
  html.ios4,
  html.ios5,
  html.ios6,
  html.ios7,
  html.ios8,
  html.ios9,
  html.ios10,
  html.ios11,
  html.android1,
  html.android2,
  html.android3,
  html.android4,
  html.android5,
  html.android6 {
    @content;
  }
}

// this function is being deprecated from bootstrap as of version 5
// copying it here to support continued usage in site codebase
@mixin bs-make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}
