.font-family-primary {
  font-weight: 400;
}

.font-family-primary-italic {
  font-weight: 400;
}

.font-family-primary-bold {
  font-weight: 700;
}

.font-family-primary-heavy {
  font-weight: 700;
}

.font-family-secondary {
  font-weight: 400;
}

.font-family-secondary-bold {
  font-weight: 600;
}

.font-family-tertiary {
  font-weight: 400;
}

.font-family-tertiary-italic {
  font-weight: 400;
}

.font-family-tertiary-bold {
  font-weight: 600;
}
