@import '../../sass/global/variables';
@import '../../sass/global/bootstrap-variables';
@import '../../sass/global/mixins';
@import '../../sass/global/functions';
@import 'bootstrap/scss/mixins';

// chinese
@include font-face(
  $font-primary,
  $lang-font-path +
    'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/regular',
  400,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-primary-italic,
  $lang-font-path +
    'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/regular',
  400,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-primary-bold,
  $lang-font-path + 'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/bold',
  700,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-primary-heavy,
  $lang-font-path + 'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/bold',
  700,
  normal,
  woff woff2 eot svg
);

@include font-face(
  $font-secondary,
  $lang-font-path +
    'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/regular',
  400,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-secondary-bold,
  $lang-font-path + 'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/bold',
  700,
  normal,
  woff woff2 eot svg
);

@include font-face(
  $font-tertiary,
  $lang-font-path +
    'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/regular',
  400,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-tertiary-italic,
  $lang-font-path +
    'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/regular',
  400,
  normal,
  woff woff2 eot svg
);
@include font-face(
  $font-tertiary-bold,
  $lang-font-path + 'noto-sans/noto-sans-tc-v11-latin_chinese-traditional/bold',
  700,
  normal,
  woff woff2 eot svg
);

// font weights
@import '_weights-noto';

// chinese-specific rules
.zh-cn {
  .font-family-primary-italic {
    font-family: $font-primary-italic, 'NotoSerif-Regular', serif !important;
  }

  .font-family-tertiary-italic {
    font-family: $font-tertiary-italic, 'NotoSans-Regular', sans-serif !important;
  }
  .font-family-secondary-bold {
    font-family: $font-secondary-bold, 'NotoSans-Bold', sans-serif;
    font-weight: 700;
  }

  .h1 {
    font-size: rem(66);
    line-height: rem(70);
    @include media-breakpoint-down(sm) {
      font-size: rem(42);
      line-height: rem(46);
    }
  }
  .h2 {
    font-size: rem(42);
    line-height: rem(46);
    @include media-breakpoint-down(sm) {
      font-size: rem(28);
      line-height: rem(31);
    }
  }
  .h3 {
    font-size: rem(42);
    line-height: rem(46);
    @include media-breakpoint-down(sm) {
      font-size: rem(28);
      line-height: rem(31);
    }
  }
  .h4 {
    font-size: rem(28);
  }

  .h5 {
    font-size: rem(28);
    letter-spacing: 0.25px;
  }

  .h6 {
    font-size: rem(18);
    letter-spacing: 0.25px;
  }

  .h7 {
    font-size: rem(14);
  }

  .h8 {
    font-size: rem(16);
  }

  .b1 {
    font-size: rem(16);
  }

  .b2 {
    font-size: rem(16);
  }

  .b3 {
    font-size: rem(22);
  }

  .b4 {
    font-size: rem(14);
  }

  .b5 {
    font-size: rem(14);
  }

  .b6 {
    font-size: rem(14);
  }

  .b7 {
    font-size: rem(14);
  }
  .b8 {
    font-size: rem(12);
  }
  .b9 {
    font-size: rem(12);
  }
  .b10 {
    font-size: rem(11);
  }

  .d1 {
    font-size: rem(80);
  }

  .n1 {
    font-size: rem(18);
  }
  .n2 {
    font-size: rem(16);
  }

  .f1 {
    line-height: rem(16);
  }

  .f2 {
    line-height: rem(16);
  }

  .cta-typography {
    font-size: rem(14);
  }
}
